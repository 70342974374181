import { motion, useScroll, useTransform } from 'framer-motion'
import { type FC, type ReactNode, useRef } from 'react'

interface TextRevealByWordProps {
	texts: string[]
	className?: string
}

export const TextRevealByWord: FC<TextRevealByWordProps> = ({ texts, className }) => {
	const targetRef = useRef<HTMLDivElement | null>(null)

	const { scrollYProgress } = useScroll({
		target: targetRef,
		offset: ['start end', 'end center'],
	})

	const allWords = texts.flatMap((text) => text.split(' '))

	return (
		<div ref={targetRef} className={`relative z-0 ${className}`}>
			<p className='flex flex-wrap'>
				{allWords.map((word, i) => {
					const start = i / allWords.length
					const end = start + 1 / allWords.length
					const key = `${word}-${i}`
					return (
						<Word key={key} progress={scrollYProgress} range={[start, end]}>
							{word === '\n' ? <br key={key} /> : word} {/* Tratamento de quebras */}
						</Word>
					)
				})}
			</p>
		</div>
	)
}

interface WordProps {
	children: ReactNode
	progress: any
	range: [number, number]
}

// Componente que anima cada palavra
const Word: FC<WordProps> = ({ children, progress, range }) => {
	const opacity = useTransform(progress, range, [0, 1])

	return (
		<motion.span style={{ opacity }} className='mx-1'>
			{children}
		</motion.span>
	)
}

export default TextRevealByWord
