import { CircleMinus, CirclePlus } from '@/icons'
import React, { useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Reveal } from '../revealPageItems'

// Definição da interface para os itens do FAQ
interface FaqItem {
	id: number
	title: string
	description: string
}

interface Faq0Props {
	faqItems: FaqItem[] // Definindo as props que o componente deve receber
}

const Faq0: React.FC<Faq0Props> = ({ faqItems }) => {
	// Permitir que o estado seja do tipo 'number' ou 'null'
	const [expandedIndex, setExpandedIndex] = useState<number | null>(0)

	// Função para alternar o item expandido
	const toggleExpand = (index: number) => {
		setExpandedIndex(expandedIndex === index ? null : index)
	}

	// Resolve findDOMNode is deprecated in StrictMode
	const nodeRef = React.useRef(null)

	return (
		<div className='mx-auto w-full max-w-[1216px]'>
			{/* Lista de FAQ */}

			{faqItems.map((item) => (
				<div key={item.id} className={`w-full border-b py-8 ${expandedIndex === item.id ? 'expanded' : ''}`}>
					<Reveal>
						<div
							className='flex w-full cursor-pointer flex-row items-center justify-between'
							onClick={() => toggleExpand(item.id)}
							onKeyUp={() => {}}
						>
							<h2 className='faq-title w-full pb-2 text-2xl leading-relaxed'>{item.title}</h2>

							<div className='ml-4 flex-shrink-0'>{expandedIndex === item.id ? <CircleMinus /> : <CirclePlus />}</div>
						</div>
					</Reveal>
					<Reveal>
						<div className='flex w-full flex-col items-end'>
							<CSSTransition
								in={expandedIndex === item.id}
								timeout={300}
								classNames='faq-transition'
								unmountOnExit
								nodeRef={nodeRef}
							>
								<p className='w-[80%] pt-4 leading-relaxed md:w-[60%]' ref={nodeRef}>
									{item.description}
								</p>
							</CSSTransition>
						</div>
					</Reveal>
				</div>
			))}

			{/* CSS para transições */}
			<style jsx>{`
        .faq-transition-enter {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
        }

        .faq-transition-enter-active {
          max-height: 500px; /* Ajuste conforme necessário para suportar mais texto */
          opacity: 1;
          transition:
            max-height 300ms ease-in-out,
            opacity 300ms ease-in-out;
        }

        .faq-transition-exit {
          max-height: 500px; /* Ajuste conforme necessário */
          opacity: 1;
          overflow: hidden;
        }

        .faq-transition-exit-active {
          max-height: 0;
          opacity: 0;
          transition:
            max-height 300ms ease-in-out,
            opacity 300ms ease-in-out;
        }

        .faq-content {
          width: 100%; /* Garante que o conteúdo ocupe a largura completa */
          overflow: hidden;
        }

        .expanded {
          width: 100%; /* Garante que o item expandido ocupe a largura completa */
        }

        .w-full {
          width: 100% !important; /* Garante que a largura seja sempre 100% */
        }

        .faq-title {
          width: 100%; /* Garante que o título ocupe a largura completa */
        }
      `}</style>
		</div>
	)
}

export default Faq0
