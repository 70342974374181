import { GoogleIconRounded, Stars5 } from '@/icons'
import Image from 'next/image'

interface Tag {
	name: string
	color: string
}

interface TestimonialsProps {
	name: string
	testimony: string
	tags: Tag[]
}

const Testimonials: React.FC<TestimonialsProps> = ({ name, testimony, tags }) => {
	return (
		<div className='flex h-fit w-full flex-col items-start justify-start rounded-2xl border p-6 md:w-[280px]'>
			<div className='flex w-full flex-row items-center justify-between pb-4'>
				<div className='flex flex-row items-center gap-3'>
					{/* <Image
            src="/image/Profile-photo.png"
            alt={`${name} profile`}
            loading="lazy"
            width={20}
            height={24}
            className="h-[24px] w-[20px]"
          /> */}
					<GoogleIconRounded />
					<p className='text-sm'>{name}</p>
				</div>
				{/* <Image
          src="/image/Stars.png"
          alt="Rating"
          loading="lazy"
          width={80}
          height={24}
          className="h-[24px] w-[80px]"
        /> */}
				<Stars5 />
			</div>
			<p className='pb-6 text-sm leading-6 text-[#747474]'>{testimony}</p>
			<div className='flex flex-row gap-2 pt-2'>
				{tags.map((tag) => (
					<p
						key={tag.name}
						className='inline-block rounded-full px-3 py-1 text-xs'
						style={{ backgroundColor: tag.color }}
					>
						{tag.name}
					</p>
				))}
			</div>
		</div>
	)
}

export default Testimonials
