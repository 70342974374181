import type React from 'react'
import Faq0 from '../frequentQuestions0'

const Section8: React.FC = () => {
	const faqItems = [
		{
			id: 1,
			title: 'O que é a Click Cannabis?',
			description:
				'A Click Cannabis é uma plataforma de telemedicina especializada em Cannabis Medicinal. Nosso objetivo é oferecer soluções de saúde alternativas, naturais e seguras, através de consultas médicas informativas, emissão de receitas médicas e suporte na autorização da Anvisa.',
		},
		{
			id: 2,
			title: 'Qual a missão da Click?',
			description:
				'A missão da Click Cannabis é promover uma vida saudável e bem-estar através de uma abordagem natural. Nosso foco é facilitar o acesso ao tratamento com cannabis medicinal, garantindo um processo simples, rápido e transparente.',
		},
		{
			id: 3,
			title: 'Por que a consulta médica na ClickCannabis é tão barata?',
			description:
				'Oferecemos consultas médicas a um custo acessível para garantir que mais pessoas possam ter os benefícios da cannabis medicinal. Nosso objetivo é democratizar o acesso ao tratamento proporcionando consultas de qualidade a um preço justo.',
		},
		{
			id: 4,
			title: 'Tenho que me associar e pagar alguma mensalidade?',
			description:
				'Não é necessário se associar nem pagar mensalidades. Para acessar nossos serviços, basta entrar em contato com nosso time de atendimento, agendar e realizar a consulta médica, sem complicações.',
		},
		{
			id: 5,
			title: 'A consulta é presencial ou online?',
			description:
				'A consulta é realizada online, via Google Meet. Este formato garante a segurança, a privacidade das informações pessoais e a conveniência para os pacientes e os médicos.',
		},
	]

	return (
		<div className='flex flex-col items-center justify-between px-4 py-40 md:flex-row'>
			<div>
				<div className='flex max-w-[1216px] justify-between pb-8 md:w-screen'>
					<h1 className='text-3xl md:text-6xl'>Dúvidas frequentes</h1>
					<div className='flex items-end'>
						<a
							href='/duvidas-frequentes'
							className='hidden items-center justify-center rounded-xl border border-brand-dark px-4 py-2 text-brand-dark md:flex'
						>
							Ver FAQ completo
						</a>
					</div>
				</div>

				<Faq0 faqItems={faqItems} />
				<div className='flex justify-center pt-16'>
					<a
						href='/duvidas-frequentes'
						className='flex items-center justify-center rounded-xl border border-brand-dark px-4 py-2 text-brand-dark md:hidden'
					>
						Ver FAQ completo
					</a>
				</div>
			</div>
		</div>
	)
}

export default Section8
