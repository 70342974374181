import Image from 'next/image'
import type React from 'react'
import { Reveal } from '../revealPageItems'

const Section3: React.FC = () => {
	return (
		<div id='como-funciona' className='flex w-full max-w-[1216px] flex-col px-4 py-32'>
			<div className='flex flex-col items-center justify-center'>
				<Reveal>
					<Image
						src='/image/Icone-Cadeado.png'
						alt='Ícone de cadeado'
						width={80}
						height={80}
						className='inline-block w-12 pb-4'
					/>
				</Reveal>
				<Reveal>
					<h3 className='pb-12 text-center text-3xl leading-snug md:text-4xl'>
						Entenda como funciona o processo da Click Cannabis
					</h3>
				</Reveal>
			</div>
			<div>
				<div className='flex flex-col flex-wrap gap-8 md:flex md:flex-row md:flex-nowrap md:justify-between'>
					{/* Seção 4 - Etapa 1 */}
					<Reveal>
						<div className='scroll-card flex flex-col items-end justify-between rounded-2xl border bg-cover bg-center bg-no-repeat p-4 md:h-[390px] md:w-[280px]'>
							<Image
								src='/image/Img-etapa-1.png'
								alt='Ícone de cadeado'
								loading='lazy'
								width={120}
								height={86}
								className='h-[86px] w-[120px]'
							/>
							<div className='rounded-lg bg-opacity-75 p-2'>
								<p className='inline-block rounded-full bg-[#DDF9AD] px-4 py-1 text-xs'>Etapa 1</p>
								<h2 className='py-3 text-xl'>Consulta médica</h2>
								<p className='text-sm leading-relaxed'>
									Faça sua consulta médica por apenas R$30, todo o processo é 100% online, com médicos de plantão 24h
									por dia.
								</p>
							</div>
						</div>
					</Reveal>
					{/* Seção 4 - Etapa 2 */}
					<Reveal>
						<div className='scroll-card flex flex-col items-end justify-between rounded-2xl border bg-cover bg-center bg-no-repeat p-4 md:h-[390px] md:w-[280px]'>
							<Image
								src='/image/Img-etapa-2.png'
								alt='Ícone de cadeado'
								loading='lazy'
								width={120}
								height={86}
								className='h-[86px] w-[120px]'
							/>

							<div className='rounded-lg bg-opacity-75 p-2'>
								<p className='inline-block rounded-full bg-[#DDF9AD] px-4 py-1 text-xs'>Etapa 2</p>
								<h2 className='py-3 text-xl'>Receita médica</h2>
								<p className='text-sm leading-relaxed'>
									Se apto ao tratamento, o médico irá fazer sua receita médica para que você possa emitir sua
									autorização.
								</p>
							</div>
						</div>
					</Reveal>
					{/* Seção 4 - Etapa 3 */}
					<Reveal>
						<div className='scroll-card flex flex-col items-end justify-between rounded-2xl border bg-cover bg-center bg-no-repeat p-4 md:h-[390px] md:w-[280px]'>
							<Image
								src='/image/Img-etapa-3.png'
								alt='Ícone de cadeado'
								loading='lazy'
								width={120}
								height={86}
								className='h-[86px] w-[120px]'
							/>
							<div className='rounded-lg bg-opacity-75 p-2'>
								<p className='inline-block rounded-full bg-[#DDF9AD] px-4 py-1 text-xs'>Etapa 3</p>
								<h2 className='py-3 text-xl'>Autorização da Anvisa</h2>
								<p className='text-sm leading-relaxed'>
									Estaremos ao seu lado durante todo o processo de documentação para importação dos medicamentos
									receitados.
								</p>
							</div>
						</div>
					</Reveal>
					{/* Seção 4 - Etapa 4 */}
					<Reveal>
						<div className='scroll-card flex flex-col items-end justify-between rounded-2xl border bg-cover bg-center bg-no-repeat p-4 md:h-[390px] md:w-[280px]'>
							<Image
								src='/image/Img-etapa-4.png'
								alt='Ícone de cadeado'
								loading='lazy'
								width={120}
								height={86}
								className='h-[86px] w-[120px]'
							/>
							<div className='rounded-lg bg-opacity-75 p-2'>
								<p className='inline-block rounded-full bg-[#DDF9AD] px-4 py-1 text-xs'>Etapa 4</p>
								<h2 className='py-3 text-xl'>Importação e entrega</h2>
								<p className='text-sm leading-relaxed'>
									Te auxiliamos com a importação diretamente dos EUA, sem impostos e com entrega em até 15 dias úteis.
								</p>
							</div>
						</div>
					</Reveal>
				</div>
			</div>
		</div>
	)
}

export default Section3
