import React, { useEffect, useRef, useState } from 'react'

interface Props {
	children: JSX.Element
	width?: 'fit-content' | '100%'
}

export const Reveal = ({ children, width = 'fit-content' }: Props) => {
	const ref = useRef<HTMLDivElement | null>(null)
	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				for (const entry of entries) {
					if (entry.isIntersecting) {
						setIsVisible(true)
						observer.disconnect()
						break
					}
				}
			},
			{ threshold: 0.1 },
		)

		if (ref.current) {
			observer.observe(ref.current)
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current)
			}
		}
	}, [])

	return (
		<div ref={ref} style={{ width }}>
			{children}
		</div>
	)
}
