'use client'

import { motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Reveal } from '../revealPageItems'

interface Section5Props {
	openModal: () => void
}

const Section5: React.FC<Section5Props> = ({ openModal }) => {
	const [selectedOption, setSelectedOption] = useState('óleo')

	const [isModalOpen1, setIsModalOpen1] = useState(false)
	const [iframeKey1, setIframeKey1] = useState(0)
	const [iframeKey2, setIframeKey2] = useState(0)
	// Função para abrir o modal
	const openModal1 = () => {
		setIsModalOpen1(true)
		setIframeKey1((prevKey) => prevKey + 1) // Increment iframe key
		document.body.classList.add('no-scroll')
		document.addEventListener('click', handleClickOutside)
	}

	const openModal2 = () => {
		setIsModalOpen2(true)
		setIframeKey2((prevKey) => prevKey + 1) // Increment iframe key
		document.body.classList.add('no-scroll')
		document.addEventListener('click', handleClickOutside)
	}
	// Função para fechar o modal
	const closeModal1 = () => {
		setIsModalOpen1(false)
		document.body.classList.remove('no-scroll') // Reativa o scroll da página ao fechar o modal
		document.removeEventListener('click', handleClickOutside)
	}

	const [isModalOpen2, setIsModalOpen2] = useState(false)

	// Função para fechar o modal
	const closeModal2 = () => {
		setIsModalOpen2(false)
		document.body.classList.remove('no-scroll') // Reativa o scroll da página ao fechar o modal
		document.removeEventListener('click', handleClickOutside)
	}
	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			const modal = document.querySelector('.modal-container')
			if (modal && !modal.contains(event.target as Node)) {
				if (isModalOpen1) {
					closeModal1()
				}
				if (isModalOpen2) {
					closeModal2()
				}
			}
		},
		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		[isModalOpen1, isModalOpen2, closeModal1, closeModal2],
	)
	useEffect(() => {
		const handleEscKey = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				closeModal1()
				closeModal2()
			}
		}

		if (isModalOpen1 || isModalOpen2) {
			document.addEventListener('keydown', handleEscKey)
		}

		return () => {
			document.removeEventListener('keydown', handleEscKey)
		}
		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	}, [isModalOpen1, isModalOpen2, closeModal1, closeModal2])

	return (
		<div className='flex w-screen flex-col items-center justify-center bg-brand-darker'>
			<div>
				<div className='mx-auto mt-24 flex w-screen flex-col items-center justify-center'>
					<div className='flex w-full max-w-[1216px] flex-col justify-between px-4 py-24 md:flex-row'>
						<div className='flex flex-col justify-between pb-24 text-4xl text-white md:w-[600px] md:pr-32'>
							<div>
								<Reveal>
									<h2 className='pb-12 leading-snug'>
										Métodos de consumo dos medicamentos à base de Cannabis medicinal
									</h2>
								</Reveal>
								<Reveal>
									<div className='flex flex-row gap-8'>
										<div
											className={`flex cursor-pointer flex-col items-center justify-center text-center text-[#76C982] ${
												selectedOption !== 'óleo' ? 'opacity-50' : ''
											}`}
											onClick={() => setSelectedOption('óleo')}
											onKeyUp={() => {}}
										>
											<motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
												<Image
													src='/image/secao1/oil-icon.svg'
													alt='Oil icon'
													width={64}
													height={64}
													className='z-20 pb-4'
												/>
											</motion.div>
											<span className='text-base'>Óleos</span>
										</div>
										<div
											className={`flex cursor-pointer flex-col items-center justify-center text-center text-[#76C982] ${
												selectedOption !== 'gummy' ? 'opacity-50' : ''
											}`}
											onClick={() => setSelectedOption('gummy')}
											onKeyUp={() => {}}
										>
											<motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
												<Image
													src='/image/secao1/gummy-icon.svg'
													alt='Gummy icon'
													width={64}
													height={64}
													className='z-20 pb-4'
												/>
											</motion.div>
											<span className='text-base'>Gummy</span>
										</div>
										<div
											className={`flex cursor-pointer flex-col items-center justify-center text-center text-[#76C982] ${
												selectedOption !== 'creme' ? 'opacity-50' : ''
											}`}
											onClick={() => setSelectedOption('creme')}
											onKeyUp={() => {}}
										>
											<motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
												<Image
													src='/image/secao1/cream-icon.svg'
													alt='Cream icon'
													width={64}
													height={64}
													className='z-20 pb-4'
												/>
											</motion.div>
											<span className='text-base'>Cremes</span>
										</div>
									</div>
								</Reveal>
							</div>

							<Reveal>
								<div className=''>
									{/* Renderiza o texto baseado na opção selecionada */}
									{selectedOption === 'óleo' && (
										<motion.p
											className='z-20 pt-20 text-base leading-relaxed'
											initial={{ opacity: 0, y: 20 }} // Animação inicial (fora de vista)
											animate={{ opacity: 1, y: 0 }} // Animação de entrada
											exit={{ opacity: 0, y: 20 }} // Animação de saída
											transition={{ duration: 0.5, ease: 'easeInOut' }} // Duração e tipo de animação
										>
											Os óleos de Cannabis são uma forma popular de consumo, permitindo uma dosagem precisa e um método
											de uso fácil. Eles são ideais para quem busca um tratamento consistente e controlado.
										</motion.p>
									)}
									{selectedOption === 'gummy' && (
										<motion.p
											className='z-20 pt-20 text-base leading-relaxed'
											initial={{ opacity: 0, y: 20 }} // Animação inicial
											animate={{ opacity: 1, y: 0 }} // Animação de entrada
											exit={{ opacity: 0, y: 20 }} // Animação de saída
											transition={{ duration: 0.5, ease: 'easeInOut' }} // Duração e tipo de animação
										>
											As gomas de Cannabis são uma alternativa prática e saborosa. Elas proporcionam uma maneira
											discreta e conveniente de consumir Cannabis Medicinal, adequadas para diferentes estilos de vida.
										</motion.p>
									)}
									{selectedOption === 'creme' && (
										<motion.p
											className='z-20 pt-20 text-base leading-relaxed'
											initial={{ opacity: 0, y: 20 }} // Animação inicial
											animate={{ opacity: 1, y: 0 }} // Animação de entrada
											exit={{ opacity: 0, y: 20 }} // Animação de saída
											transition={{ duration: 0.5, ease: 'easeInOut' }} // Duração e tipo de animação
										>
											Os cremes de Cannabis Medicinal são aplicados diretamente na pele, sendo eficazes no tratamento de
											dores locais e inflamações. Eles oferecem uma abordagem direcionada e são fáceis de usar em
											qualquer momento do dia.
										</motion.p>
									)}
								</div>
							</Reveal>
						</div>

						<Reveal>
							<div className='grid w-full max-w-[800px] cursor-pointer grid-cols-1 gap-16 p-4 md:grid-cols-2 md:gap-4'>
								{/* Iframe 1 */}
								<div onClick={openModal1} onKeyUp={() => {}} className='relative flex w-full flex-col items-center'>
									<Image
										src='/image/thumb-mitos.webp'
										alt='Imagem de Depoimento'
										className='rounded-2xl'
										width={1080}
										height={1920}
									/>
									<motion.div
										className='left-30 absolute top-48 z-20 flex h-16 w-16 items-center justify-center rounded-full bg-[#F7D805] pl-1'
										style={{ transform: 'translate(-50%, -50%)' }}
										animate={{ scale: [1, 1.2, 1] }}
										transition={{ duration: 1.5, repeat: Number.POSITIVE_INFINITY }}
									>
										<Image src='/image/play-icon.svg' alt='Thumbnail' width={26} height={30} />
									</motion.div>

									<span className='pb-2 pt-4 text-center text-lg leading-relaxed text-white'>
										Cannabis medicinal vicia?
									</span>
								</div>

								{/* Iframe 2 */}
								<div
									onClick={openModal2}
									onKeyUp={() => {}}
									className='relative flex w-full cursor-pointer flex-col items-center'
								>
									<Image
										src='/image/thumb-mitos.webp' // Substitui pela imagem desejada
										alt='Imagem de Depoimento'
										className='rounded-2xl'
										width={1080}
										height={1920}
									/>
									<motion.div
										className='left-30 absolute top-48 z-20 flex h-16 w-16 items-center justify-center rounded-full bg-[#F7D805] pl-1'
										style={{ transform: 'translate(-50%, -50%)' }}
										animate={{ scale: [1, 1.2, 1] }} // Pulse effect
										transition={{ duration: 1.5, repeat: Number.POSITIVE_INFINITY }} // Loop infinito
									>
										<Image src='/image/play-icon.svg' alt='Thumbnail' width={26} height={30} />
									</motion.div>

									<span className='pb-2 pt-4 text-center text-lg leading-relaxed text-white'>
										Cannabis medicinal causa euforia?
									</span>
								</div>

								{/* Script Vimeo API */}
								<script src='https://player.vimeo.com/api/player.js' />
							</div>
						</Reveal>
					</div>
					<div className='flex max-w-[1216px] flex-col items-start justify-start gap-32 px-4 pt-24'>
						<div>
							<Reveal>
								<h1 className='pb-6 text-start text-xl leading-snug text-[#76C982] md:pb-12 md:text-3xl'>
									Carteirinha Click Cannabis
								</h1>
							</Reveal>
							<Reveal>
								<h1 className='pb-2 text-start text-4xl leading-snug text-white md:pr-16 md:text-8xl md:leading-snug'>
									Leve seus documentos para todos os lugares, sem preocupações
								</h1>
							</Reveal>
						</div>
						<div className='flex max-w-[1216px] flex-col-reverse items-end justify-between md:w-screen md:flex-row'>
							<div>
								<span>
									<Image
										src='/image/Carteirinha-Click-Roberto.png'
										alt='Carteirinha Click Roberto'
										loading='lazy'
										width={600}
										height={525}
										className='h-auto w-full object-contain' // ou object-cover
									/>
								</span>
							</div>
							<div className='flex max-w-[400px] flex-col gap-8 pb-24 text-white'>
								<Reveal>
									<p className='text-lg leading-relaxed'>
										Suas informações médicas estarão sempre ao seu alcance, de forma digital e prática. Tudo o que você
										precisa, em um só lugar, disponível diretamente no seu celular.{' '}
									</p>
								</Reveal>
								<Reveal>
									<div className='flex flex-row gap-4'>
										<motion.button
											className='rounded-xl bg-[#F7DF37] px-4 py-2 text-black'
											whileHover={{ scale: 1.05 }}
											whileTap={{ scale: 0.95 }}
											onClick={openModal}
										>
											Solicite a sua
										</motion.button>

										<motion.button
											onClick={openModal}
											className='rounded-xl border border-white px-4 py-2 text-white'
											whileHover={{ scale: 1.05 }}
											whileTap={{ scale: 0.95 }}
										>
											Quero ser paciente
										</motion.button>
									</div>
								</Reveal>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Modal com o vídeo */}
			{/* Modal with Video 1 */}
			{isModalOpen1 && (
				<div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70'>
					<div className='modal-container relative h-[60%] rounded-lg bg-transparent md:h-[80%]'>
						<button
							type='button'
							className='absolute right-2 top-2 h-8 w-8 rounded-full bg-white text-black transition-colors hover:bg-black hover:text-white'
							onClick={closeModal1}
						>
							X
						</button>
						<iframe
							key={iframeKey1}
							className='h-full w-full rounded-2xl'
							src='https://player.vimeo.com/video/1011482748?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1'
							allow='fullscreen; picture-in-picture; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture'
							title='Depoimento'
							loading='lazy'
						/>
					</div>
				</div>
			)}
			{/* Modal with Video 2 */}
			{isModalOpen2 && (
				<div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70'>
					<div className='modal-container relative h-[60%] rounded-lg bg-transparent md:h-[80%]'>
						<button
							type='button'
							className='absolute right-2 top-2 h-8 w-8 rounded-full bg-white text-black transition-colors hover:bg-black hover:text-white'
							onClick={closeModal2}
						>
							X
						</button>
						<iframe
							key={iframeKey2}
							className='h-full w-full rounded-2xl'
							src='https://player.vimeo.com/video/1011482530?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1'
							allow='fullscreen; picture-in-picture; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture'
							title='Depoimento'
							loading='lazy'
						/>
					</div>
				</div>
			)}
		</div>
	)
}

export default Section5
