import { motion } from 'framer-motion'
import Image from 'next/image'
import type React from 'react' // Import useRef
import { useRef } from 'react'
import { Reveal } from '../revealPageItems'
import Testimonials from '../testimonials'

const Section4: React.FC = () => {
	const buttonRef = useRef(null) // Definindo um useRef para o botão

	const testimonial1Tags = [{ name: 'Processo', color: '#FFE5E5' }]
	const testimonial2Tags = [{ name: 'Importação', color: '#CCFFE5' }]
	const testimonial3Tags = [{ name: 'Consulta', color: '#CCFFE5' }]
	const testimonial4Tags = [{ name: 'Insônia', color: '#CCFFE5' }]
	const testimonial5Tags = [{ name: 'Ansiedade', color: '#CCFFE5' }]
	const testimonial6Tags = [{ name: 'Dores', color: '#CCFFE5' }]

	const testimonialsData = [
		{
			id: 1,
			name: 'Jeanne P.',
			testimony:
				'Minha experiência com a Click Cannabis foi incrível! O atendimento foi excepcional, com uma equipe extremamente competente e ágil em cada etapa do processo. Os medicamentos chegaram rapidamente, e estou ansiosa para iniciar meu tratamento contra a ansiedade. Desde a marcação da consulta até a finalização da compra, recebi um suporte excepcional. O médico foi muito atencioso, e a assessoria via WhatsApp foi ótima. Recomendo totalmente!',
			tags: [...testimonial2Tags, ...testimonial1Tags],
		},
		{
			id: 2,
			name: 'Marcio P.',
			testimony:
				'O processo é rápido e amistoso, são 100% presentes até a entrega da medicação sanando dúvidas, auxiliando com a documentação, o apoio continua após o recebimento da medicação. Equipe médica é muito competente, recomendo!',
			tags: testimonial1Tags,
		},
		{
			id: 3,
			name: 'Vanda L.',
			testimony:
				'A minha experiência com a Clickcanabis foi excelente, do primeiro contato a chegada do produto na minha casa, recebi todas as informações necessárias, excelente atendimento na consulta médica e todo o acompanhamento do processo de importação do produto com informações claras e precisas. Já iniciei meu tratamento e estou muito feliz e satisfeita com o atendimento da Clickcanabis. PARABÉNS a equipe da Clickcanabis.',
			tags: testimonial1Tags,
		},
		{
			id: 4,
			name: 'Valéria F.',
			testimony:
				'Foi tudo muito bom. Do primeiro atendimento, até a consulta, e o término do processo. Muito bom mesmo. Tem 1 semana que comecei a usar e estou começando a ver resultado. As dores melhoraram um pouco, acredito que com o passar do uso vai melhorar mais. Estou dormindo melhor. Com certeza indico o uso para quem precisa.',
			tags: testimonial1Tags,
		},
		{
			id: 5,
			name: 'Roberto L.',
			testimony:
				'Por enquanto está tudo certo com a documentação e o time da click cannabis são altamente qualificados e educados com seus clientes. Nota 10 para o atendimento e fora o tratamento que eles tem com os paciente. A consulta com a médica foi muito boa. Expliquei todos meus problemas ela ouviu e tem me ajudado e nós conversamos muito...... Parabéns a equipe click cannabis',
			tags: testimonial1Tags,
		},
		{
			id: 6,
			name: 'Jorge R.',
			testimony:
				'Eu pesquisei em vários grupos de atendimento canabico um grupo que faria todos os trâmites legais desde a consulta médica, documentação da Anvisa e a facilidade da aquisição dos produtos e encontrei aqui na Click cannabis todo esse empenho, mediante tudo isso e o preço mais acessível, resolvi fechar com a Click',
			tags: testimonial1Tags,
		},
		{
			id: 7,
			name: 'Tamires Freitas',
			testimony:
				'Atendimento de excelência, me passando muita confiança e tranquilidade no processo. A médica que me acompanha é muito atenciosa, me explicou todos os detalhes e se disponibilizou para qualquer dúvida durante o uso. O produto chegou dentro do esperado, a todo tempo recebi comunicações sobre o envio e chegou tudo certinho. Só gratidão por essa experiência!',
			tags: testimonial1Tags,
		},
		{
			id: 8,
			name: 'Luciano A.',
			testimony:
				'Confesso que no início fiquei um pouco inseguro de receber o produto pelo valor, mas durante todo o processo fui acompanhando e sendo sempre informado de cada passo, foi mais fácil do que pensei, e agora vou poder começar meu tratamento com a cannabis. Muito obrigado a ClickCannabis que tornou isso possível!!!🙏🏻',
			tags: testimonial1Tags,
		},
		{
			id: 9,
			name: 'Gladys P.',
			testimony:
				'Passando para agradecer! Foi tudo prático e rápido e eu gostei da experiência! Estava querendo conhecer mais esse tratamento para ansiedade. A consulta sendo on-line e o preço, acessível, foi fundamental para viabilizar. Já recebi meus vidrinhos e iniciei o tratamento e estou adorando. Estou mais tranquila e dormindo melhor. Muito bom!',
			tags: testimonial1Tags,
		},
		{
			id: 10,
			name: 'Salete P.',
			testimony:
				'Confesso que no início fiquei desconfiada, pois é tanto golpe por aí a fora. Mas, a cada fase, desde a consulta até a compra e a chegada do remédio, fui sempre bem acompanhada em tudo. Já tinha usado o CANABIDIOL brasileiro, mas nem se compara com esse. Estou muitíssima satisfeita!🙏 Se alguém quiser conversar comigo, pode me chamar pelo Facebook.',
			tags: testimonial1Tags,
		},
		{
			id: 11,
			name: 'Alexandre L.',
			testimony:
				'Boa tarde, estou muito satisfeito com click cannabis, pq fui muito bem assessorado por toda equipe, recebi os medicamentos com toda as burocracias muito rápido e agora começar o tratamento!! Os medicamentos chegaram muito bem lacrados e em perfeito estado !! Obrigado a todos por tudo !! Gratidão!!',
			tags: testimonial1Tags,
		},
		{
			id: 12,
			name: 'Allan M.',
			testimony:
				'Perfeito, óleo de CBD é tão bom que é difícil imaginar algum medicamento que funcione tão bem e possua tantos benefícios. A equipe da click é sensacional, disposta a ajudar com qualquer dificuldade ou necessidade de seus clientes, simplesmente perfeito! Chegou tudo certinho e dentro do prazo.',
			tags: testimonial1Tags,
		},
	]

	return (
		<div id='depoimentos' className='flex max-w-[1216px] flex-col items-center justify-center py-32'>
			<div className='flex flex-col items-center justify-between md:flex-row md:items-end md:px-0'>
				<Reveal>
					<Image
						src='/image/Icone-Cadeado.png'
						alt='Ícone de cadeado'
						width={80}
						height={80}
						className='inline-block w-12 pb-4 md:hidden'
					/>
				</Reveal>
				<Reveal>
					<h1 className='px-4 text-center text-2xl leading-snug md:text-start md:text-5xl md:leading-normal'>
						Veja os relatos de membros da comunidade Click Cannabis
					</h1>
				</Reveal>
				<Reveal>
					<p className='px-4 pb-2 pt-4 text-center text-sm md:w-[380px] md:text-start md:text-base md:leading-relaxed'>
						Prezamos pela sua experiência durante todo o processo, e temos muito orgulho em dizer que somos a empresa
						com mais avaliações no Google.
					</p>
				</Reveal>
			</div>
			<div className='relative mt-16 flex w-full flex-col items-start justify-between px-4 md:flex-row md:px-0'>
				<div className='pointer-events-none absolute inset-x-0 bottom-0 z-10 h-[40%] bg-gradient-to-t from-white to-white/0' />

				<div className='hidden flex-col gap-8 md:flex'>
					<Reveal>
						<Testimonials
							name='Jeanne P.'
							testimony='Minha experiência com a Click Cannabis foi incrível! O atendimento foi excepcional, com uma equipe extremamente competente e ágil em cada etapa do processo. Os medicamentos chegaram rapidamente, e estou ansiosa para iniciar meu tratamento contra a ansiedade. Desde a marcação da consulta até a finalização da compra, recebi um suporte excepcional. O médico foi muito atencioso, e a assessoria via WhatsApp foi ótima. Recomendo totalmente!'
							tags={[...testimonial2Tags, ...testimonial1Tags]}
						/>
					</Reveal>
					<Reveal>
						<Testimonials
							name='Marcio P.'
							testimony='O processo é rápido e amistoso, são 100% presentes até a entrega da medicação sanando dúvidas, auxiliando com a documentação, o apoio continua após o recebimento da medicação. Equipe médica é muito competente, recomendo!'
							tags={testimonial1Tags}
						/>
					</Reveal>
					<Reveal>
						<Testimonials
							name='Vanda L.'
							testimony='A minha experiência com a Clickcanabis foi excelente, do primeiro contato a chegada do produto na minha casa, recebi todas as informações necessárias, excelente atendimento na consulta médica e todo o acompanhamento do processo de importação do produto com informações claras e precisas. Já iniciei meu tratamento e estou muito feliz e satisfeita com o atendimento da Clickcanabis. PARABÉNS a equipe da Clickcanabis.'
							tags={testimonial1Tags}
						/>
					</Reveal>
				</div>
				<div className='hidden flex-col gap-8 md:flex'>
					<Reveal>
						<Testimonials
							name='Valéria F.'
							testimony='Foi tudo muito bom. Do primeiro atendimento, até a consulta,e o término do processo. Muito bom mesmo. Tem 1 semana que comecei a usar e estou começando a ver resultado. As dores melhoraram um pouco, acredito que com o passar do uso vai melhorar mais. Estou dormindo melhor. Com certeza indico o uso para quem precisa.'
							tags={testimonial1Tags}
						/>
					</Reveal>
					<Reveal>
						<Testimonials
							name='Roberto L.'
							testimony='Por enquanto está tudo certo com a documentação e o time da click cannabis são altamente qualificados e educados com seus clientes. Nota 10 para o atendimento e fora o tratamento que eles tem com os paciente. A consulta com a médica foi muito boa. Expliquei todos meus problemas ela ouviu e tem me ajudado e nós conversamos muito...... Parabéns a equipe click cannabis'
							tags={testimonial1Tags}
						/>
					</Reveal>
					<Reveal>
						<Testimonials
							name='Jorge R.'
							testimony='Eu pesquisei em vários grupos de atendimento canabico um grupo que faria todos os trâmites legais desde a consulta médica, documentação da Anvisa e a facilidade da aquisição dos produtos e encontrei aqui na Click cannabis todo esse empenho, mediante tudo isso e o preço mais acessível, resolvi fechar com a Click
'
							tags={testimonial1Tags}
						/>
					</Reveal>
				</div>
				<div className='hidden flex-col gap-8 md:flex'>
					<Reveal>
						<Testimonials
							name='Tamires Freitas'
							testimony='Atendimento de excelência, me passando muita confiança e tranquilidade no processo. A médica que me acompanha é muito atenciosa, me explicou todos os detalhes e se disponibilizou para qualquer dúvida durante o uso. O produto chegou dentro do esperado, a todo tempo recebi comunicações sobre o envio e chegou tudo certinho. Só gratidão por essa experiência!'
							tags={testimonial1Tags}
						/>
					</Reveal>
					<Reveal>
						<Testimonials
							name='Luciano A.'
							testimony='Confesso que no início fiquei um pouco inseguro de receber o produto pelo valor,mas durante todo o processo fui acompanhando e sendo sempre informado de cada passo, doi mais facil do que pensei,e agora vou poder começar meu tratamento com a cannabis,muito obrigado a ClickCannabis que tornou isso possível!!!🙏🏻
'
							tags={testimonial1Tags}
						/>
					</Reveal>
					<Reveal>
						<Testimonials
							name='Gladys P.'
							testimony='Passando para agradecer! Foi tudo prático e rápido e eu gostei da experiência! Estava querendo conhecer mais esse tratamento para ansiedade. A consulta sendo on-line e o preço, acessível, foi fundamental para viabilizar. Já recebi meus vidrinhos e iniciei o tratamento e estou adorando. Estou mais tranquila e dormindo melhor. Muito bom!'
							tags={testimonial1Tags}
						/>
					</Reveal>
				</div>
				<div className='flex flex-col gap-8'>
					<Reveal>
						<Testimonials
							name='Salete P.'
							testimony='Confesso que no início fiquei desconfiada, pois, é tanto golpe por aí a fora. Mas, a cada fase, desde a consulta até a compra e a chegada do remédio, fui sempre bem acompanhada em tudo. Já tinha usado o CANABIDIOL brasileiro, mas, nem se compara com esse. Estou muitíssima satisfeita!🙏 Se alguém quiser conversar comigo, pode me chamar pelo Facebook.'
							tags={testimonial1Tags}
						/>
					</Reveal>
					<Reveal>
						<Testimonials
							name='Alexandre L.'
							testimony='Boa tarde ,estou muito satisfeito com click cannabis, pq fui muito bem assessorado por toda equipe recebi os medicamentos com toda as burocracias muito rápido e agora começar o tratamento!!
Os medicamentos chegaram muito bem lacrados e em perfeito estado !!
Obrigado a todos por tudo !!
Gratidão!!'
							tags={testimonial1Tags}
						/>
					</Reveal>
					<Reveal>
						<Testimonials
							name='Allan M.'
							testimony='Perfeito, óleo de cbd é tão bom que é difícil imaginar algum medicamento que funcione tão bem e possua tantos benefícios. A equipe da click é sensacional disposta a ajudar com qualquer dificuldade ou necessidade de seus clientes, simplesmente perfeito! Chegou tudo certinho e dentro do prazo.'
							tags={testimonial1Tags}
						/>
					</Reveal>
				</div>
				<div className='overlay-rectangle' />
			</div>
			<div className='z-30 mt-[-50px]'>
				<Reveal>
					<a
						href='https://bit.ly/3U6l2iL' // Link desejado
						target='_blank' // Abre em uma nova aba
						rel='noopener noreferrer' // Para segurança em links externos
					>
						<motion.button
							className='z-30 rounded-xl bg-brand-dark px-6 py-4 text-white'
							whileHover={{ scale: 1.05 }}
							whileTap={{ scale: 0.95 }}
						>
							Ver depoimentos no Google
						</motion.button>
					</a>
				</Reveal>
			</div>
		</div>
	)
}

export default Section4
