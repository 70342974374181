import type React from 'react'
import { useEffect, useState } from 'react'
import PatologyForm from '../patologyForm'
import { Reveal } from '../revealPageItems'

interface Section1Props {
	selectedPatologies: string[]
	onSelectPatology: (patology: string) => void
	openModal: () => void
}

const Section1: React.FC<Section1Props> = ({ selectedPatologies, onSelectPatology, openModal }) => {
	const [showButton, setShowButton] = useState(false)

	const patologies = [
		'Alcoolismo',
		'Alzheimer',
		'Ansiedade',
		'Bipolaridade',
		'Diabetes',
		'Dores',
		'Anorexia',
		'Autismo',
		'Crohn',
		'Depressão',
		'Epilepsia',
		'Enxaqueca',
		'Fibromialgia',
		'Insônia',
		'Parkinson',
		'Tabagismo',
		'Glaucoma',
		'TDAH',
		'Esclerose múltipla',
		'Intestino irritável',
	]

	useEffect(() => {
		if (selectedPatologies.length > 0) {
			setShowButton(true)
		} else {
			setShowButton(false)
		}
	}, [selectedPatologies])

	return (
		<>
			<div className='-mt-10 text-transparent md:m-0'>
				{/* HACK LCP */}
				Médicos prescritores de Cannabis Medicinal, 100% online
			</div>
			<div className='flex h-auto w-screen flex-col items-end justify-between px-4 pb-24 pt-24 md:min-h-screen md:px-8 md:pt-8'>
				<div className='flex w-full items-start'>
					<h1 className='relative mb-8 flex max-w-[1200px] flex-row flex-wrap items-start gap-3 text-3xl md:gap-8 md:text-8xl'>
						Médicos prescritores de Cannabis Medicinal, 100% online
					</h1>
				</div>
				<div className='flex w-full max-w-[1200px] flex-col items-end gap-8'>
					<p className='text-base md:text-xl'>
						Selecione uma das patologias abaixo e inicie seu tratamento ainda hoje:
					</p>

					<Reveal>
						<PatologyForm
							patologies={patologies}
							selectedPatologies={selectedPatologies}
							onSelectPatology={onSelectPatology}
						/>
					</Reveal>
				</div>
			</div>

			{showButton && (
				<div className='fixed bottom-4 right-4 z-40'>
					<button type='button' onClick={openModal} className='rounded-md bg-blue-500 px-4 py-2 text-white shadow-lg'>
						Falar com médico
					</button>
				</div>
			)}
		</>
	)
}

export default Section1
