import { Stars5 } from '@/icons'
import { motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import { Reveal } from '../revealPageItems'

interface Section2Props {
	openModal: () => void
}

const Section2: React.FC<Section2Props> = ({ openModal }) => {
	const [showParallaxItems, setShowParallaxItems] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowParallaxItems(true)
		}, 5000)

		return () => clearTimeout(timer)
	}, [])

	const handleImageClick = () => {
		setIsModalOpen(true)
		document.body.classList.add('no-scroll')
		document.addEventListener('click', handleClickOutside)
	}

	const handleCloseModal = useCallback(() => {
		setIsModalOpen(false)
		document.body.classList.remove('no-scroll')
		document.removeEventListener('click', handleClickOutside)
	}, [])

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			const modal = document.querySelector('.modal-container')
			if (modal && !modal.contains(event.target as Node)) {
				handleCloseModal()
			}
		},
		[handleCloseModal],
	)

	useEffect(() => {
		const handleEscKey = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				handleCloseModal()
			}
		}

		if (isModalOpen) {
			document.addEventListener('keydown', handleEscKey)
		} else {
			document.removeEventListener('keydown', handleEscKey)
		}

		return () => {
			document.removeEventListener('keydown', handleEscKey)
		}
	}, [isModalOpen, handleCloseModal])

	return (
		<ParallaxProvider>
			<div>
				<div className='flex flex-col items-center justify-center px-4 py-24 md:py-20'>
					<Reveal>
						<Image
							src='/image/Icone-Cadeado.png'
							alt='Ícone de cadeado'
							width={80}
							height={80}
							className='inline-block w-12 pb-4 md:hidden'
						/>
					</Reveal>
					<Reveal>
						<h2 className='relative max-w-[1216px] pb-2 text-center text-4xl leading-snug md:text-8xl md:leading-snug'>
							Democratizando o acesso a cannabis medicinal
						</h2>
					</Reveal>
					<div className='flex flex-col items-center justify-center'>
						<div className='mt-8 flex items-center justify-center'>
							<Reveal>
								<div
									onClick={handleImageClick}
									onKeyUp={() => {}}
									className='relative flex flex-col items-center justify-center'
								>
									<span className='absolute bottom-8 z-10 text-center text-xl text-white md:text-4xl'>
										Clique aqui para ver o vídeo
									</span>

									{/* Animação de Pulse aplicada ao ícone de play */}
									<motion.div
										className='absolute z-20 flex h-16 w-16 items-center justify-center rounded-full bg-brand-light pl-1'
										animate={{ scale: [1, 1.2, 1] }} // Pulse effect
										transition={{ duration: 1.5, repeat: Number.POSITIVE_INFINITY }} // Loop infinito
									>
										<Image src='/image/play-icon.svg' alt='Thumbnail' width={26} height={30} />
									</motion.div>

									{/* Imagem com sobreposição de fade */}
									<div className='relative'>
										<Image
											className='mt-2 flex w-full cursor-pointer rounded-2xl md:w-[800px]'
											src='/image/thumbnails-new.webp'
											alt='Thumbnail'
											width={936}
											height={600}
										/>

										{/* Overlay com o efeito de fade */}
										<div className='pointer-events-none absolute inset-0 h-full w-full rounded-2xl bg-gradient-to-t from-[rgba(0,0,0,0.5)] via-transparent to-transparent' />
									</div>
								</div>
							</Reveal>

							{showParallaxItems && (
								<>
									<Parallax translateY={['10px', '-300px']}>
										<div className='bottom-[-120px] left-[20px] hidden w-[280px] flex-row gap-4 rounded-xl border border-[#D8E9DB] bg-white p-2.5 md:absolute md:flex'>
											<Image src='/image/Icon-Consulta-s1.png' alt='Icone consulta' width={24} height={24} />
											<p>
												Consulta por apenas <b>R$30</b>
											</p>
										</div>
									</Parallax>

									<Parallax translateY={['50px', '-500px']}>
										<div className='absolute right-[820px] top-[60px] z-10 hidden w-[250px] flex-col gap-4 rounded-xl border border-[#D8E9DB] bg-white px-3 py-4 md:flex'>
											<p className='text-sm'>
												“Atendimento maravilhoso, e a medicação tem funcionado com a minha filha. Super indico.”
											</p>
											<div className='flex flex-row items-center justify-between gap-4'>
												<p className='text-sm font-bold'>Thais Santana</p>
												<Stars5 />
											</div>
										</div>
									</Parallax>
								</>
							)}
						</div>
						<Reveal>
							<p className='py-8 text-center text-sm leading-relaxed md:max-w-[440px] md:text-base'>
								Assista o video e veja como iniciar seu tratamento com a nova medicina natural e alternativa.
							</p>
						</Reveal>
						<Reveal>
							<div className='flex flex-row gap-4'>
								<motion.button
									className='rounded-xl bg-brand-dark px-4 py-2 text-white'
									whileHover={{ scale: 1.05 }}
									whileTap={{ scale: 0.95 }}
									onClick={openModal}
								>
									Falar com médico
								</motion.button>
								<a href='#como-funciona'>
									<motion.button
										className='rounded-xl border border-brand-dark px-4 py-2 text-brand-dark'
										whileHover={{ scale: 1.05 }}
										whileTap={{ scale: 0.95 }}
									>
										Como funciona
									</motion.button>
								</a>
							</div>
						</Reveal>
					</div>
				</div>

				{isModalOpen && (
					<div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70'>
						<div className='modal-container relative h-[50%] w-[90%] bg-transparent md:h-[450px] md:w-[800px]'>
							<button
								type='button'
								className='absolute right-2 top-2 h-8 w-8 rounded-full bg-white text-black'
								onClick={handleCloseModal}
							>
								X
							</button>
							<iframe
								className='h-full w-full rounded-2xl'
								src='https://player.vimeo.com/video/1010392256?autoplay=1&loop=1&autopause=0&player_id=0&app_id=58479'
								allow='fullscreen; picture-in-picture; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture'
								title='Mitos Verdades 2'
								loading='lazy'
							/>
						</div>
					</div>
				)}
			</div>
		</ParallaxProvider>
	)
}

export default Section2
