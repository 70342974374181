'use client'

import { AnimatePresence, motion } from 'framer-motion'
import type React from 'react'
import { type CSSProperties, useState } from 'react'

type PatologyFormProps = {
	patologies: string[]
	selectedPatologies: string[]
	onSelectPatology: (patology: string) => void
	sx?: {
		container?: CSSProperties
	}
}

const PatologyForm: React.FC<PatologyFormProps> = ({ patologies, selectedPatologies, onSelectPatology, sx }) => {
	const [glowPatology, setGlowPatology] = useState<string | null>(null)

	const handleSelectPatology = (patology: string) => {
		onSelectPatology(patology)
		setGlowPatology(patology)
		setTimeout(() => setGlowPatology(null), 1000)
	}

	return (
		<div
			style={sx?.container}
			className='grid grid-cols-2 items-center justify-center gap-4 md:grid md:grid-cols-4 md:items-end md:justify-end'
		>
			{patologies.map((patology) => (
				<motion.label
					key={patology}
					className={`flex w-full cursor-pointer items-center rounded-full border px-4 py-2 text-base transition-colors duration-300 md:w-auto ${
						selectedPatologies.includes(patology) ? 'bg-brand text-white' : 'bg-white text-black'
					} ${glowPatology === patology ? 'glow' : ''}`}
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					layout
				>
					<motion.div
						className={`mr-2 flex min-h-4 min-w-4 items-center justify-center rounded-full border ${
							selectedPatologies.includes(patology) ? 'border-white bg-white' : 'border-gray-500'
						}`}
						layout
					>
						<AnimatePresence>
							{selectedPatologies.includes(patology) && (
								<motion.div
									className='h-1 w-1 rounded-full bg-brand'
									layout
									initial={{ scale: 0 }}
									animate={{ scale: 1 }}
									exit={{ scale: 0 }}
									transition={{ duration: 0.3 }}
								/>
							)}
						</AnimatePresence>
					</motion.div>
					<input
						type='checkbox'
						name='patology'
						value={patology}
						checked={selectedPatologies.includes(patology)}
						onChange={() => handleSelectPatology(patology)}
						className='hidden'
					/>
					{patology}
				</motion.label>
			))}
		</div>
	)
}

export default PatologyForm
